"use strict";

const CONFIGURATION = {
  "mapOptions": {
    "center": {"lat": 48.8566, "lng": 2.3522},
    "zoom": 15,
    "styles": [
      { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
      { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#000000' }] }, // Noms des villes en noir
      { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#dadada' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#92bae9' }] // Bleu pour les mers et océans
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
      },
      {
        featureType: 'landscape.natural.landcover', // Utilisation de beige pour les zones naturelles couvertes
        elementType: 'geometry',
        stylers: [{ color: '#f5f1ea' }]
      },
      {
        featureType: 'landscape.natural.terrain', // Utilisation de beige pour le terrain naturel
        elementType: 'geometry',
        stylers: [{ color: '#f5f1ea' }]
      }
    ]
  },
};

document.addEventListener("turbo:load", initSimpleMap);
document.addEventListener("turbo:render", initSimpleMap);

export function initSimpleMap() {
  console.log("initSimpleMap called");
  const {Map, Marker} = google.maps;

  const mapElement = document.getElementById('gmp-map');
  if (!mapElement) {
    console.log('Element with id "gmp-map" not found. Skipping map initialization.');
    return;
  }
  const lat = parseFloat(mapElement.getAttribute('data-latitude'));
  const lng = parseFloat(mapElement.getAttribute('data-longitude'));

  if (!isNaN(lat) && !isNaN(lng)) {
    const position = {lat: lat, lng: lng};
    const map = new Map(mapElement, Object.assign({}, CONFIGURATION.mapOptions, {center: position}));

    // Utilisation d'un marqueur personnalisé
    const markerIcon = {
      url: '/assets/logo_maps.webp', // Chemin vers votre image
      scaledSize: new google.maps.Size(22.8, 35) // Taille de l'icône
    };

    new Marker({
      position: position,
      map: map,
      icon: markerIcon, // Spécifiez ici l'icône personnalisée
      title: 'Votre texte de légende' // Légende facultative pour le marqueur
    });
  }
}

