document.addEventListener("DOMContentLoaded", function() {
  const consent = getCookie('cookiesConsent');
  
  if (consent === 'false') {
    window.clarity = function() {}; // Désactiver Clarity
  } else if (consent === 'true') {
    setTimeout(() => {
      if (typeof window.loadClarity === 'function') {
        window.loadClarity();
      }
    }, 500);
  }

  // Affiche la modal si le consentement aux cookies n'a pas encore été donné
  if (!getCookie('cookiesConsent')) {
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => {
        const cookiesModal = document.getElementById('fontModal');
        if (cookiesModal) {
          cookiesModal.classList.add('show');
          cookiesModal.style.display = 'block';
          document.body.classList.add('modal-open');
        }
      });
    } else {
      setTimeout(() => {
        const cookiesModal = document.getElementById('fontModal');
        if (cookiesModal) {
          cookiesModal.classList.add('show');
          cookiesModal.style.display = 'block';
          document.body.classList.add('modal-open');
        }
      }, 100); // Fallback si requestIdleCallback n'est pas supporté
    }
  }

  var acceptButton = document.getElementById('yesButton');
  var refuseButton = document.getElementById('noButton');

  if (acceptButton) {
    acceptButton.addEventListener('click', function() {
      closeCookiesModal();
      setCookie('cookiesConsent', 'true', 365); // L'utilisateur accepte les cookies
      updateConsentStatus('granted');
      // Chargez ici la balise Google Analytics si l'utilisateur accepte
      loadGoogleAnalytics(); // Fonction pour charger Google Analytics
      loadGoogleTagManager(); // Fonction pour charger Google Tag Manager

      setTimeout(() => {
        if (typeof window.loadClarity === 'function') {
          window.loadClarity();
        }
      }, 500);
    });
  }

  if (refuseButton) {
    refuseButton.addEventListener('click', function() {
      closeCookiesModal();
      setCookie('cookiesConsent', 'false', 365); // L'utilisateur refuse les cookies
      updateConsentStatus('denied');
      window.clarity = function() {};
      location.reload();
    });
  }
});

function updateConsentStatus(status) {
  // Configuration du consent mode de Google selon la réponse de l'utilisateur
  gtag('consent', 'update', {
    'analytics_storage': status, // 'granted' ou 'denied'
    'ad_storage': status          // 'granted' ou 'denied'
  });
}

function loadGoogleAnalytics() {
  // Google tag (gtag.js)
  var script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-3MNFCR23R5';
  document.head.appendChild(script);

  script.onload = function() {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-3MNFCR23R5');
  };
}

function loadGoogleTagManager() {
  (function(w,d,s,l,i){
    w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
    j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-NH4M4N3B');
}

function closeCookiesModal() {
  var cookiesModal = document.getElementById('fontModal'); // Utilisez le même id ou changez selon votre HTML
  if (cookiesModal) {
    cookiesModal.classList.remove('show');
    cookiesModal.style.display = 'none';
    document.body.classList.remove('modal-open');
  }
}

// Fonctions utilitaires pour gérer les cookies
function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + value + expires + '; path=/';
}

function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}